<template>
    <article
        v-if="entry"
        id="main"
        role="main"
        tabindex="-1"
    >
        <TheNavigation
            v-if="!menuHidden"
            :call-to-action="callToAction"
        />
        <HeroLogo v-else />

        <HeroDefault
            :title="pageTitle"
            :description="pageDescription"
            class="hero-default--display"
        />

        <BlogCategory
            v-for="(category, key) in categories"
            :key="key"
            :category="category"
        />

        <ClientOnly>
            <UnibuddyBubble v-if="showUnibuddy" />
        </ClientOnly>
    </article>
</template>

<script setup>
import getBlog from '~/graphql/queries/getBlog.graphql';
import getCategories from '~/graphql/queries/getCategories.graphql';
import { BLOG_CATEGORY } from '~/constants/categories-and-tags.js';

const { $i18n } = useNuxtApp();
const siteHandle = $i18n?.localeProperties?.value?.craftSiteHandle;
const blogVariables = computed(() => {
    return {
        siteHandle
    };
});

const blogResponse = await useAsyncQuery({
    query: getBlog,
    blogVariables
});

const { craftEntry: entry } = useCraftPageEther(blogResponse);

const categoryVariables = computed(() => {
    return {
        siteHandle,
        group: BLOG_CATEGORY
    };
});

const categoryResponse = await useAsyncQuery({
    query: getCategories,
    categoryVariables
});

const categories = computed(() => {
    if (categoryResponse && categoryResponse.data) {
        return categoryResponse?.data?.value?.categories;
    }

    return null;
});

const callToAction = computed(() => entry.value?.callToAction?.[0] ?? null);
const showUnibuddy = computed(() => entry.value?.showUnibuddy ?? false);
const pageDescription = computed(() => entry.value?.richTextSimple ?? '');
const pageTitle = computed(() => entry.value?.title ?? '');
const menuHidden = computed(() => entry.value?.hideMenu ?? false);
</script>
